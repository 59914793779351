import React, {useEffect, useState} from 'react';
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import axios from 'axios';

function FooterPopup() {

    const [visible, setVisible] = useState(false)
    const [step, setStep] = useState(0)
    const [options, setOptions] = useState({})

    const [subsidiaries, setSubsidiaries] = useState([])
    const [enable, setEnable] = useState(true)

    useEffect(() => {
        let ageAccess = sessionStorage.getItem("age_access");
        if (localStorage?.age_access !== '1' || ageAccess !== '1') {
            axios.get('/local/php_interface/enterego_class/Subsidiary/ajax.php'
            ).then(response => {
                setOptions(response.data.options)
                setSubsidiaries(response.data.subsidiaries)
                setEnable(response.data.enable)
                setStep(1)
                setVisible(true)
            })
        } else {
            sessionStorage.setItem("age_access", "1");
            setVisible(false)
        }

    }, []);

    if (!visible) {
        return null;
    }

    return (
        <div className="flex items-center justify-center fixed z-40 h-screen w-screen dark:bg-fancyboxDark top-0 left-0
        bg-fancybox overlay-box">
            <div className="md:rounded-lg rounded-xl dark:bg-darkBox bg-white rounded-0 relative z-50 p-8 m-auto
            md:max-w-lg max-w-md w-screen md:h-auto">
                <Step1 step={step} setStep={setStep} options={options}/>
                <Step2 step={step} setStep={setStep}/>
                <Step3 step={step} setStep={setStep} subsidiaries={subsidiaries}
                       enable={enable} setVisible={setVisible}/>

            </div>
        </div>
    );
}

export default FooterPopup;