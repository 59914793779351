import React, {useState} from 'react';
import PropTypes from "prop-types";
import axios from "axios";

Step3.propTypes = {
    step: PropTypes.number,
    setStep: PropTypes.func,
    setVisible: PropTypes.func,
    enable: PropTypes.bool,
    subsidiaries: PropTypes.array,
}

function Step3(props) {

    const [selectSubsidiary, setSelectSubsidiary] = useState('')
    const onSubmitClick = (e) => {
        e.preventDefault();

        if (selectSubsidiary) {
            axios.get('/local/ajax/subsidiary.php?subsidiary=' + selectSubsidiary)
                .then(response => {
                    if (response.data === 'reload') {
                        window.location.reload();
                    } else if (response.data === 'success') {
                        props.setStep(0)
                        props.setVisible(false)
                    }
                })
        }
    }

    if (props.step !== 3) {
        return null;
    }

    return (
        <div className="age-access-inner">
            <div className="age-access__text">
                <h1 className="text-dark font-medium dark:font-light text-xl dark:text-textDarkLightGray
                             mb-5 ">3 / 3</h1>
                <h1 className="text-dark font-medium dark:font-extralight text-lg dark:text-textDarkLightGray
                             mb-4 text-center">
                    Какой филиал ближе к Вам?</h1>
            </div>
            <form onSubmit={(e) => onSubmitClick(e)} method="GET" id="modal-subsidiary-form">
                <div className="flex flex-col">
                    {props.subsidiaries.map((subsidiary, key) => (
                        <div key={key} className={`mt-3 md:p-5 p-3 rounded-xl text-textLight dark:text-textDark dark:bg-lightGrayBg border border-grey-line-order cursor-pointer
                                ${selectSubsidiary === subsidiary.LID ? 'dark:border-white border-light-red' : 'dark:border-0'} `}
                             onClick={() => setSelectSubsidiary(subsidiary.LID)}
                        >
                            {subsidiary.NAME}
                        </div>
                    ))}
                    <div className={`mt-3 md:p-5 p-3 rounded-xl text-textLight dark:text-textDark dark:bg-lightGrayBg border border-grey-line-order cursor-pointer
                                ${selectSubsidiary === 'CUSTOM' ? 'dark:border-white border-light-red' : 'dark:border-0'} `}
                         onClick={() => setSelectSubsidiary('CUSTOM')}
                    >
                        Другой регион
                    </div>

                </div>
                <div className="age-access__buttons flex flex-row justify-center mt-5">
                    <button disabled={selectSubsidiary === ''}
                            type="submit"
                            className="text-center md:px-9 px-4 py-2 block w-2/5 osh-prmBtn"
                            onClick={(e) => onSubmitClick(e)}
                    >
                        Выбрать
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Step3;