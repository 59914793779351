import React from 'react';

function ClaimTheme({listThemes, setTheme, theme}) {

    return (
        <div className="mb-4 flex flex-col">
            <label
                className="xl:text-sm text-xs font-medium dark:font-light text-dark dark:text-textDarkLightGray">
                <b className="text-hover-red"> * </b> Тема обращения</label>
            {
                listThemes !== undefined ?
                    <select onChange={(e) => {
                        setTheme(e.target.value)
                    }}
                            value={theme}
                            className="mt-3 xl:text-sm text-xs dark:text-white dark:bg-grayButton
                                         text-dark bg-textDark border-transparent w-auto border-none
                                         rounded-md p-3 ring-0 focus:ring-0 focus:border-transparent">
                        {
                            listThemes.map((themeItem, i) => {
                                return (
                                    <option value={themeItem[1].ID} key={i}>
                                        {themeItem[1].VALUE}
                                    </option>
                                )
                            })
                        }
                    </select> :
                    false
            }
        </div>
    )
}

export default ClaimTheme;
