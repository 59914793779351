import React, {useEffect, useState} from "react";
import {createRoot} from "react-dom/client";
import axios from "axios";

document.addEventListener('DOMContentLoaded', function () {
    const root = document.getElementById('js-initial-popup');
    const elements = document.querySelectorAll('.js__callback');
    elements.forEach(elem => {
        elem.addEventListener('click', function () {

            if (root) {
                createRoot(root).render(<CallbackModal/>)
            }
        })
    })

    $(document).on('submit', '.callback_form', function (e) {

    });
})

function CallbackModal() {

    const [visible, setVisible] = useState(true)
    const [loader, setLoader] = useState(false)
    const onSubmit = (e) => {
        e.preventDefault();

        let errors = {
                emptyField: 'Поле не заполнено',
                emptyConfirm: 'Не приняты условия обработки персональных данных',
            },
            fieldPhone = e.target.querySelector('input[name="PHONE"]'),
            fieldConfirm = e.target.querySelector('input[name="confirm"]'),
            err = 0;

        e.target.querySelectorAll('.js__error_field').forEach(el => {
            el.innerHTML = ''
            el.style.display = 'none'
        });

        if (fieldPhone.value.length <= 0) {
            const errorNode = fieldPhone.closest('.form-group').querySelector('.js__error_field')
            errorNode.innerHTML = errors.emptyField
            errorNode.style.display = 'block'
            console.log('phone error');
            err++;
        }

        if (!fieldConfirm.checked) {
            const errorNode = fieldConfirm.closest('.form-group').querySelector('.js__error_field')
            errorNode.innerHTML = errors.emptyConfirm
            errorNode.style.display = 'block'
            console.log('check error');
            err++;
        }
        const formData = new FormData(e.target)
        formData.append('recaptcha_token', window.recaptcha.getToken())

        if (!err) {

            setLoader(true)
            axios.post('/ajax/callback.php', formData).then(response => {
                if (response.data === 1) {
                    e.target.style.display = 'none';
                    e.target.closest('div').querySelector('.js-result-callback').style.display = 'block';
                } else {
                    e.target.querySelector('.js-error-form').style.display = 'block';
                }
                setLoader(false)
            })
        }
        return false;
    }

    useEffect(() => {
        $('input[data-name="PHONE-FORM"]').inputmask("+7 (999)-999-9999", {clearMaskOnLostFocus: false});
        $('.form-form [data-name="EMAIL"]').inputmask('email');

        $('.callback_PHONE').inputmask("+7 (999)-999-9999", {clearMaskOnLostFocus: false});
    }, []);

    if (visible===false) {
        return null;
    }

    return (<div className="flex items-center justify-center fixed z-40 h-screen w-screen dark:bg-fancyboxDark top-0 left-0
        bg-fancybox overlay-box">
        <div id="callbackModal" className="md:rounded-xl dark:bg-darkBox bg-white rounded-lg md:p-8 p-5 relative md:max-w-xl
         w-full">
            <div className="cursor-pointer absolute top-2 right-2" onClick={() => setVisible(false)}>
                <svg width="40" height="40" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.5"
                          d="M55 30C55 43.807 43.807 55 30 55C16.1929 55 5 43.807 5 30C5 16.1929 16.1929 5 30 5C43.807 5 55 16.1929 55 30Z"
                          className="dark:fill-[#464646] fill-textDarkLightGray"></path>
                    <path
                        d="M22.4242 22.4242C23.1564 21.6919 24.3436 21.6919 25.0757 22.4242L30 27.3485L34.9242 22.4242C35.6565 21.692 36.8435 21.692 37.5757 22.4242C38.308 23.1564 38.308 24.3436 37.5757 25.076L32.6517 30L37.5757 34.924C38.308 35.6562 38.308 36.8435 37.5757 37.5757C36.8435 38.308 35.6562 38.308 34.924 37.5757L30 32.6517L25.076 37.5757C24.3436 38.308 23.1564 38.308 22.4242 37.5757C21.692 36.8435 21.692 35.6565 22.4242 34.9242L27.3485 30L22.4242 25.0757C21.6919 24.3436 21.6919 23.1564 22.4242 22.4242Z"
                        className="fill-darkBox dark:fill-white"></path>
                </svg>
            </div>
            <div className="flex_block_cols">
                <h4 className="text-center font-medium mb-4 text-textLight dark:text-textDarkLightGray text-lg">
                    Заказать обратный звонок
                </h4>
                <div className="block_text text-xs font-normal mb-2 text-textLight dark:text-textDarkLightGray">
                    <span
                        className="block_text_sub text-xs font-medium mb-2 text-textLight dark:text-textDarkLightGray">
                        Отдел поддержки работает с 10:00 до 20:00, ежедневно.
                    </span><br/>
                    Оставьте ваш номер и мы перезвоним вам в ближайшее рабочее время.
                </div>
                <form method="POST" className="callback_form" onSubmit={onSubmit}
                >
                    <input type="hidden" name="recaptcha_token" value=""/>
                    <div className="form-group mb-3">
                        <input
                            type="text"
                            name="PHONE"
                            className="PHONE callback_PHONE bg-textDark p-3 dark:bg-grayButton cursor-pointer w-full
                                text-textLight rounded-md dark:text-white border-0 text-xl"
                            placeholder="Ваш номер"
                        />
                        <div className="text-red-500 text-sm mt-1 js__error_field"></div>
                    </div>
                    <div className="form-group mb-3">
                        <div className="form-control input_lk my-4" style={{height: 'auto'}}>
                            <input name="confirm" id="confirmCall" type="checkbox"
                                   className="p-3 dark:bg-grayButton checked:hover:bg-grayButton border-0
                                    dark:text-white cursor-pointer text-textLight font-normal rounded-full bg-textDark
                                    checked:focus:bg-grayButton"/>
                            <label htmlFor="confirmCall"
                                   className="ml-2 text-xs dark:font-normal font-medium text-textLight dark:text-textDarkLightGray">
                                Даю согласие на обработку
                                <a href="/about/users_rules/"
                                   target="_blank" className="underline">персональных данных</a>
                            </label>
                        </div>
                        <p className="custom__title text-xs dark:font-normal font-medium text-textLight dark:text-textDarkLightGray my-5">
                            <a href="/about/politics/"
                               target="_blank" className="underline">Политика конфиденциальности</a>
                        </p>
                        <div className="text-red-500 text-sm mt-1 js__error_field"></div>
                    </div>
                    <div className="text-red-500 text-sm my-2 js-error-form" style={{display: "none"}}>
                        Ошибка отправки данных
                    </div>
                    <div className="submit-wrap">
                        <button type="submit" disabled={loader} className="p-3 w-full text-base cursor-pointer osh-prmBtn">
                            {loader ? <div
                                    id="loader"
                                    className="w-6 h-6 border-2 border-t-transparent border-red-800
                                        rounded-full animate-spin"
                                >
                                </div>
                                : <span>Оправить</span>
                            }

                        </button>
                    </div>
                </form>
                <div className="js-result-callback text-textLight dark:text-white mt-5 text-center text-xl" style={{display: "none"}}>
                    Ваша заявка отправлена
                </div>
            </div>
        </div>
    </div>)
}