import React from 'react';

function ClaimMessage({setMessageText,messageText}) {

    return (
        <div className="mb-4">
            <label
                className="xl:text-sm text-xs font-medium dark:font-light text-dark dark:text-textDarkLightGray">
                <b className="text-hover-red"> * </b> Опишите проблему
            </label>
            <textarea
                required
                onChange={(e) => {
                    setMessageText(e.target.value)
                }}
                minLength={10}
                rows={3}
                value={messageText}
                className={'dark:bg-grayButton text-sm bg-textDark border-none py-3 px-4' +
                    ' focus:border-transparent outline-none rounded-md w-full mt-2'}
                placeholder="Введите текст сообщения">
                                    </textarea>
        </div>
    )
}

export default ClaimMessage;