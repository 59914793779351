import React from 'react';

function ClaimError({errors}) {
    return (
        errors?.map((error, index) =>
            <p key={index} className="text-hover-red text-xs my-1">{error}</p>
            )
    )
}

export default ClaimError;