import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import axios from "axios";

function ClaimFormFiles({setFiles, setTmpFiles, files, setSubmit}) {
    // Обработчик изменения инпута
    const onDrop = (acceptedFiles => {
        setFiles(acceptedFiles)
        uploadFiles(acceptedFiles)
    });

    const deleteFILESCallback = (fileItem) => {
        let indexRemove = fileDelete.findIndex(file => fileItem === file.file);
        if (indexRemove !== -1) {
            let fileId = fileDelete[indexRemove].id;
            deleteFiles(fileItem, indexRemove, fileId).then(res => {
                if (res) {
                    fileDelete.splice(indexRemove, 1)
                    setFileDelete(fileDelete)
                    let curFiles = files.filter(file => file !== fileItem)
                    setFiles(curFiles);
                    setTmpFiles(prevFiles => prevFiles.filter(file => file !== fileId));
                }
            })
        }
    }

    const [fileDelete, setFileDelete] = useState([])
    let num = 0;
    const [errorUpload, setErrorUpload] = useState('')
    const uploadFiles = (files) => {
        let listTmpFiles = [];
        let listFilesExpectation = files.length;
        let listFilesDelete = [];
        loaderForSite('appendLoader')
        files.forEach(async (file) => {
            const fileList = new FormData()
            fileList.append('file', file)
            fileList.append('action', 'filesAdd')
            await axios.post('/local/ajax/orderClaim.php', fileList).then(res => {
                let error = ''
                if (res?.status !== 200) {
                    error = 'Не удалось добавить файл'
                } else {
                    if (res.status === 200) {
                        listTmpFiles.push(res.data)
                        listFilesDelete.push({file: file, id: res.data})
                        if (listFilesExpectation === listTmpFiles.length) {
                            setSubmit(true);
                        }
                    } else {
                        error = res.data
                    }
                }
                setErrorUpload(error)
                return error === '' ? res.data.data : null
            })
        })
        setFileDelete(listFilesDelete)
        setTmpFiles(listTmpFiles)
        loaderForSite('remove')
    }

    async function deleteFiles(item, indexRemove, fileId) {
        let result = false;
        await axios.post('/local/ajax/orderClaim.php', {
            id: fileId,
            action: 'deleteFile'
        }).then(res => {
            console.log(res)
            let error = ''
            if (res?.status !== 200) {
                error = 'Не удалось удалить файл'
            } else {
                if (res.status === 200) {
                    result = true;
                    setErrorUpload(error)
                } else {
                    error = res.data
                }
            }
            return error === '' ? res.data.data : null
        })
        return result;
    }


    const {getInputProps, open, getRootProps} = useDropzone({
        onDrop, noClick: true, noKeyboard: true, accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/gif': ['.gif'],
            'application/pdf': ['.pdf'],
            'video/mp4': ['.mp4', '.MP4']
        }
    })

    return (<form className="mb-4" encType="multipart/form-data"  {...getRootProps()}>
        <label
            className="xl:text-sm text-xs font-medium dark:font-light text-dark dark:text-textDarkLightGray">
            <b className="text-hover-red"> * </b> Файлы
        </label>
        <div className="form-control mt-2 input_lk input_files drop-zone rounded-lg border border-dashed
             border-gray-900/25 dark:border-tagFilterGray py-3 px-5" id="drop-zone">
            <label
                className="mt-2 flex flex-col mb-2 relative upload-file-label">
                <div className="flex flex-row items-center">
                    <svg className="h-10 w-10 text-gray-300" viewBox="0 0 24 24"
                         fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd"
                              d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25
                                  2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021
                                   18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06
                                   1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0
                                   112.25 0 1.125 1.125 0 01-2.25 0z"
                              clipRule="evenodd"></path>
                    </svg>
                    <div className="my-1 text-sm leading-6 text-gray-600 flex flex-col">
                        <div className="cursor-pointer rounded-md underline font-normal ml-4 dark:text-white
                             text-hover-red" onClick={open}> Выберите файлы
                        </div>
                    </div>
                </div>
                <span className="text-xs leading-5 text-gray-600 dark:text-whiteOpacity">
                        .png, .jpg, .jpeg, .gif, .mp4 up to 50MB
                    </span>
                <div className="drop-message text-[10px] text-dark dark:text-whiteOpacity w-full">
                    Приложить можно до 10 изображений и видео в форматах .png, .jpg, .jpeg, .gif, .mp4
                    объемом не более 50 Мб
                </div>
            </label>
            <ul className="flex flex-row flex-wrap">
                {errorUpload}
                {errorUpload === '' && files?.length > 0 ? files?.map((item, i) => {
                    num++
                    return (
                        <div className="p-2 border border-tagFilterGray w-28 relative rounded-md mb-2 mr-3"
                             key={i}>
                            <span className="absolute -top-2 -right-3 cursor-pointer" onClick={() => {
                                deleteFILESCallback(item)
                            }}>
                                <svg width="25" height="25" viewBox="0 0 58 57" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5"
                                      d="M53.0338 28.5C53.0338 41.6166 42.2409 52.25 28.9275 52.25C15.614 52.25
                                       4.82129 41.6166 4.82129 28.5C4.82129 15.3832 15.614 4.75 28.9275 4.75C42.2409
                                       4.75 53.0338 15.3832 53.0338 28.5Z"
                                      fill="#D9D9D9"></path><path
                                    d="M21.6226 21.303C22.3286 20.6073 23.4734 20.6073 24.1793 21.303L28.9275 25.9811L33.6757
                                21.303C34.3818 20.6074 35.5264 20.6074 36.2325 21.303C36.9385 21.9986 36.9385 23.1265
                                 36.2325 23.8222L31.4845 28.5L36.2325 33.1778C36.9385 33.8734 36.9385 35.0013 36.2325
                                 35.697C35.5264 36.3926 34.3816 36.3926 33.6755 35.697L28.9275 31.0192L24.1796
                                  35.697C23.4734 36.3926 22.3286 36.3926 21.6226 35.697C20.9166 35.0013 20.9166 33.8737
                                  21.6226 33.178L26.3708 28.5L21.6226 23.822C20.9165 23.1264 20.9165 21.9986 21.6226 21.303Z"
                                    fill="#1C1C1C"></path></svg>
                            </span>
                            {(item.type !== 'video/mp4' || item.type !== 'application/pdf') && URL.createObjectURL(item) !== '' ?
                                <img src={URL.createObjectURL(item)} className="mb-1"/> : false}
                            <div
                                className="text-[10px] text-dark dark:text-white text-ellipsis whitespace-nowrap overflow-hidden w-18"
                                key={i}>{num}) {item.name}</div>
                        </div>)
                }) : false}
            </ul>
        </div>
        <input type="file" {...getInputProps()} name="upload-files" id="upload-files"
               className="file-upload sr-only w-full h-full top-0 left-0 z-10"
               placeholder="Выберите файл или перетащите сюда"
               multiple="multiple"
               accept=".png, .jpg, .jpeg, .gif, .mp3, .mp4, .mov"/>
    </form>)
}

export default ClaimFormFiles;
