import React, {useState} from 'react';
import axios from "axios";
import ClaimListProduct from "./ClaimListProduct";
import ClaimButton from "./ClaimButton";
import ClaimTitle from "./propsForm/ClaimTitle";
import ClaimFormFiles from "./propsForm/ClaimFormFiles";
import ClaimTheme from "./propsForm/ClaimTheme";
import ClaimMessage from "./propsForm/ClaimMessage";
import ClaimError from "./propsForm/ClaimError";

function PersonalOrderClaims({order_id, orderNumber}) {
    const [popupVisible, setPopupVisible] = useState(false);
    const [order, setOrder] = useState({})
    const [listBasket, setListBasket] = useState([])
    const [listBasketCurrency, setListBasketCurrency] = useState([])
    const [listThemes, setListThemes] = useState([])
    const [theme, setTheme] = useState('')
    const [messageText, setMessageText] = useState('')
    const [productList, setProductList] = useState([])
    const [files, setFiles] = useState([])
    const [tmpFiles, setTmpFiles] = useState([])
    const [errors, setErrors] = useState([])
    const [keyHeader, setKeyHeader] = useState(1);
    const [submit, setSubmit] = useState(false);

    function getDataFoSendForm() {
        return {
            action: 'creatClaim',
            theme: theme,
            messageText: messageText,
            productList: productList,
            files: JSON.stringify(files),
            orderId: order_id,
            tmpFiles: tmpFiles,
            orderNumber: orderNumber,
        }
    }

    function createClaim() {
        const data = getDataFoSendForm();
        const tmpErrors = validateFormData(data)
        if (tmpErrors.length === 0) {
            sendFormAsync(data).then(res => {
                if (res.data !== 'error') {
                    clearForm()
                    setPopupVisible(false)
                }
                loaderForSite('remove')
            });
        } else {
            loaderForSite('remove')
        }
        setErrors(tmpErrors);
    }

    function createNewReason() {
        const data = getDataFoSendForm();
        const tmpErrors = validateFormData(data)
        if (tmpErrors.length === 0) {
            sendFormAsync(data).then(res => {
                if (res.data !== 'error') {
                    clearForm()
                    setKeyHeader(keyHeader + 1)
                    setPopupVisible(true)
                }
                loaderForSite('remove')
            });
        } else {
            loaderForSite('remove')
        }
        setErrors(tmpErrors);
    }

    function clearForm() {
        setPopupVisible(false)
        setFiles([])
        setMessageText('')
        setProductList([])
        listBasket.map((item, key) => {
            item[1].CHECKED = false;
        })
        document.querySelectorAll('.quantity-prod-claims').forEach((elem, k) => {
            elem.value = ''
        })
        setListBasketCurrency([...listBasket])
        setTheme(listThemes[0][0])
    }

    function validateFormData(data) {

        let tmpErrors = [];

        if (data.productList.length <= 0) {
            tmpErrors.push('Не выбраны товары для претензии!')
        }
        if (data.messageText === '') {
            tmpErrors.push('Описание проблемы не заполнено')
        }

        if (data.theme === '') {
            tmpErrors.push('Тема обращения не выбрана')
        }

        if (data.tmpFiles?.length <= 0) {
            tmpErrors.push('Не загружены файлы, подтверждающие проблему')
        }

        return tmpErrors
    }

    async function sendFormAsync(data) {
        loaderForSite('appendLoader')
        return await axios.post('/local/ajax/orderClaim.php', data)
    }

    function getDataForCreateClaim() {
        loaderForSite('appendLoader')
        axios.post('/local/ajax/orderClaim.php', {
            order_id: order_id, action: 'getOrderForCreateClaim'
        }).then(res => {
            const orderData = res.data;
            if (orderData?.DATE_CREATE_ORDER !== '') {
                setOrder({
                    date_create: orderData?.DATE_CREATE_ORDER
                })
                setListBasket(Object.entries(orderData?.PRODUCTS));
                setListBasketCurrency((Object.entries(orderData?.PRODUCTS)))
                setListThemes(Object.entries(orderData?.REASONS))
                setTheme(Object.entries(orderData?.REASONS)[0][0])
                loaderForSite('')
            } else if (orderData?.error) {
                if (orderData?.error?.code) {
                    alert('Ошибка создания претензии - обратитесь с этой проблемой к нашему менеджеру')
                }
            } else {
                console.log(orderData)
            }
        })
    }

    return (<div className="w-auto">
        <ClaimButton setPopupVisible={setPopupVisible} getDataForCreateClaim={getDataForCreateClaim}
                     order_id={order_id} orderNumber={orderNumber}/>
        {popupVisible ? <div
            className="fixed z-50 top-0 left-0 w-full bg-lightOpacityWindow dark:bg-darkOpacityWindow h-full flex justify-center">
            <div
                className="px-5 py-4 bg-white dark:bg-darkBox lg:max-w-6xl md:max-w-4xl w-full xl:mt-20 lg:mt-16 md:mt-14 md:h-fit h-full
                 rounded-lg overflow-auto md:max-h-[90vh]">
                <ClaimTitle orderNumber={orderNumber} order={order} setPopupVisible={setPopupVisible}
                            clearForm={clearForm}/>
                <ClaimError errors={errors}/>
                <div
                    className="border rounded-lg border-textDarkLightGray dark:border-grayButton relative">
                    <span
                        className="absolute -left-2 -top-2 text-xs px-2.5 py-1 bg-light-red text-white font-semibold dark:bg-dark-red rounded-full">{keyHeader}</span>
                    <div className="flex md:flex-row flex-col-reverse">
                        <div className="flex flex-col md:w-1/2 w-full p-5 pb-0">
                            <ClaimTheme listThemes={listThemes} setTheme={setTheme} theme={theme}/>
                            <ClaimMessage setMessageText={setMessageText} messageText={messageText}/>
                            <ClaimFormFiles setFiles={setFiles} setTmpFiles={setTmpFiles} files={files}
                                            setSubmit={setSubmit}/>
                            <label onClick={() => {
                                if (submit) {
                                    createNewReason();
                                }
                            }} className="cursor-pointer xl:text-sm text-xs font-medium underline dark:font-light
                                     text-dark dark:text-textDarkLightGray w-fit"> Добавить еще одну претензию</label>
                            <div onClick={() => {
                                if (submit) {
                                    createClaim();
                                }
                            }} className={'md:px-16 px-3 py-3 my-3 dark:bg-dark-red rounded-md bg-light-red ' +
                                'dark:shadow-md text-center shadow-shadowDark md:w-fit w-full ' +
                                'dark:hover:bg-hoverRedDark cursor-pointer text-white md:text-[15px] text-xs ' + (submit ? '' : 'opacity-50')}>
                                Отправить
                            </div>
                        </div>
                        <ClaimListProduct listBasketCurrency={listBasketCurrency}
                                          setListBasketCurrency={setListBasketCurrency}
                                          productList={productList}
                                          setProductList={setProductList}/>
                    </div>
                </div>
            </div>
        </div> : false}
    </div>)
}

export default PersonalOrderClaims;