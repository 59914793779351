import React from 'react';

function ClaimListProduct({listBasketCurrency, setListBasketCurrency, productList, setProductList}) {

    const handleClickOnProductList = (itemId, value, action) => {
        if (action === 'list') {
            let items = productList.filter((item) => item.product === itemId);
            if (items.length > 0) {
                if (value === false) {
                    let removeIndex = productList.findIndex(obj => obj.product === itemId)
                    productList.splice(removeIndex, 1);
                }
            } else {
                if (value === true) {
                    productList.push({product: itemId, quantity: 0})
                    setProductList(productList)
                }
            }
        }

        if (action === 'quantity') {
            let items = productList.filter((item) => item.product === itemId);
            if (items.length > 0) {
                items[0].quantity = value;
            } else {
                productList.push({product: itemId, quantity: value})
                setProductList(productList)
            }
        }
    }

    const setCheckedInCurrentListItems = (itemId, value) => {
        let items = listBasketCurrency.filter((item) => parseInt(item[0]) === itemId);
        items[0][1].CHECKED = !!value;
        setListBasketCurrency([...listBasketCurrency])
    }

    return (
        <div className="flex flex-col md:w-1/2 w-full p-5 pb-0">
            <div className="mb-3">
                <label
                    className="xl:text-sm block text-xs font-medium dark:font-light text-dark dark:text-textDarkLightGray">
                    <b className="text-hover-red"> *  </b> Проблемные товары
                </label>
                <div className="p-3 dark:bg-grayButton bg-textDark w-full mt-3 rounded-md">
                    <div
                        className="flex flex-col items-center w-full h-full md:max-h-[480px] max-h-[300px] overflow-auto">
                        {
                            listBasketCurrency !== undefined ?
                                listBasketCurrency.map((item, k) => {
                                    let itemData = item[1];
                                    return (<div className="w-full flex flex-row mb-2" key={k}>
                                        <div
                                            className="w-3/4 h-full mr-2">
                                            <input type="checkbox"
                                                   required
                                                   checked={itemData.CHECKED}
                                                   onChange={(e) => {
                                                       handleClickOnProductList(itemData.ID, e.target.checked, 'list')
                                                       setCheckedInCurrentListItems(itemData.ID, e.target.checked)
                                                   }}
                                                   className="check_input form-check-input p-3.5 mr-3 dark:bg-tagFilterGray
                                                         checked:hover:bg-tagFilterGray border-none dark:border-none
                                                        dark:text-white cursor-pointer font-normal rounded-lg
                                                        text-light-red checked:focus:bg-tagFilterGray "
                                                   placeholder=""/>
                                            <label
                                                className="text-xs font-normal text-dark dark:text-textDarkLightGray">
                                                {itemData.NAME}
                                            </label>
                                        </div>
                                        <div className="w-1/4 h-full">
                                            <input type="number"
                                                   max={itemData.QUANTITY}
                                                   required
                                                   onChange={(e) => {
                                                       if (Number(e.target.value) > itemData.QUANTITY) {
                                                           e.target.value = itemData.QUANTITY;
                                                       }
                                                       handleClickOnProductList(itemData.ID, e.target.value, 'quantity')
                                                   }}
                                                   className={'dark:bg-tagFilterGray bg-white border-none p-1 md:p-2.5 ' +
                                                       ' outline-0 rounded-md w-full focus:outline-0 quantity-prod-claims'}
                                                   placeholder="0"/>
                                        </div>
                                    </div>)
                                })
                                : false
                        }
                    </div>
                </div>
                <p className="text-[10px] mt-2 font-light dark:font-extralight text-dark
                                     dark:text-textDark mb-2">Вы указываете только то количество, с которым возникли
                    проблемы.</p>
                <p className="xl:text-sm text-xs font-medium text-hover-red"> * Обязательные
                    поля</p>
            </div>
        </div>
    )
}

export default ClaimListProduct;