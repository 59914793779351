document.addEventListener("DOMContentLoaded", function () {
    setSubsidiary()
})
export function setSubsidiary() {
    const subsidary = document.querySelectorAll('.js--subsidiary_link');
    if (subsidary) {
        subsidary.forEach(node => {
            node.addEventListener('change', (e) => {
                    loaderForSite('appendLoader')
                    BX.ajax({
                        url: '/local/ajax/subsidiary.php',
                        method: 'POST',
                        data: {subsidiary: e.target.value},
                        onsuccess: function (response) {
                            console.log(response)
                            if (response === 'success' || response === 'reload') {
                                location.reload()
                                document.addEventListener("DOMContentLoaded", () => {
                                    loaderForSite('remove')
                                });
                            }
                        }
                    })
                }
            )
        });
    }
}