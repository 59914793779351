import React from 'react';

function ClaimTitle({orderNumber,setPopupVisible,order,clearForm}) {
    return (
        <div className="mb-3 flex flex-row justify-between items-center">
            <p className="xl:text-xl lg:text-lg text-sm font-semibold dark:font-light">
                Создание претензии по заказу №{orderNumber} от {order.date_create}
            </p>
            <svg onClick={() => {
                setPopupVisible(false)
                clearForm()
            }} width="40" height="40" viewBox="0 0 58 57" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5"
                      d="M53.0338 28.5C53.0338 41.6166 42.2409 52.25 28.9275 52.25C15.614 52.25 4.82129 41.6166 4.82129 28.5C4.82129 15.3832 15.614 4.75 28.9275 4.75C42.2409 4.75 53.0338 15.3832 53.0338 28.5Z"
                      fill="#D9D9D9"/>
                <path
                    d="M21.6226 21.303C22.3286 20.6073 23.4734 20.6073 24.1793 21.303L28.9275 25.9811L33.6757 21.303C34.3818 20.6074 35.5264 20.6074 36.2325 21.303C36.9385 21.9986 36.9385 23.1265 36.2325 23.8222L31.4845 28.5L36.2325 33.1778C36.9385 33.8734 36.9385 35.0013 36.2325 35.697C35.5264 36.3926 34.3816 36.3926 33.6755 35.697L28.9275 31.0192L24.1796 35.697C23.4734 36.3926 22.3286 36.3926 21.6226 35.697C20.9166 35.0013 20.9166 33.8737 21.6226 33.178L26.3708 28.5L21.6226 23.822C20.9165 23.1264 20.9165 21.9986 21.6226 21.303Z"
                    fill="#1C1C1C"/>
            </svg>
        </div>
    )
}

export default ClaimTitle;