import React from 'react';
import PropTypes from "prop-types";

Step2.propTypes = {
    step: PropTypes.number,
    setStep: PropTypes.func,
}
function Step2({step, setStep}) {
    const onCloseClick = () => {
        setStep(3)

        localStorage.setItem('age_access', '1')
        sessionStorage.setItem("age_access", "1")
    }
    if (step !== 2) {
        return null;
    }
    return (
        <div className="age-access-inner">
            <div className="age-access__text">
                <h1 className="text-dark font-medium dark:font-light text-xl dark:text-textDarkLightGray
                             mb-5 ">2 / 3</h1>
                <h1 className="text-dark font-medium dark:font-extralight text-lg dark:text-textDarkLightGray
                             mb-4 text-center">
                    Заказываете для <b>Себя</b> или для <b>Бизнеса?</b></h1>
            </div>
            <div className="age-access__buttons flex flex-row justify-between">
                <a href="#" className="link_red_button border-greenButton border-2 px-3.5 py-2 rounded-md block w-3/5
                mr-3 md:text-sm text-xs font-medium text-center text-greenButton dark:border-none dark:bg-grayButton
                dark:font-light shadow-lg dark:text-white"
                   data-option="1" data-auth="false" onClick={onCloseClick}>Для бизнеса</a>
                <a href="https://oshisha.net/" className="link_red_button text-center
                            border-light-red border-2 md:px-9 px-4 py-2 rounded-lg block w-2/5 text-light-red font-medium
                            dark:border-none dark:bg-dark dark:font-light dark:text-white shadow-lg md:text-sm text-xs"
                   rel="nofollow">Для себя</a>
            </div>
        </div>
    );
}

export default Step2;