import ContragentList from "./components/contragents/ContragentList";
import {createRoot} from 'react-dom/client';
import React, {lazy, StrictMode, Suspense} from "react";
const CatalogProductPopup = lazy(() =>
    import("./components/catalog/CatalogProductPopup"));
import "./script";
import "./subsidiary"
import "./menu"
import "./emblaSiler";
import PersonalOrderClaims from "./components/claims/PersonalOrderClaims";
import AuthorizationForm from "./components/ctweb/AuthorizationForm";
import AuthorizationDialog from "./components/ctweb/AuthorizationDialog";
import FooterPopup from "./components/footer_popup/FooterPopup";

import "./components/CallbackModal"
import "./css/index.css"

//TODO first render on click and fix correct position on first click
import './popUpNoContragent'

/**
 * AUTH POPUP
 */

const nodePhone = document.getElementById('js-register-form-field-phone');
if (nodePhone) {

    const phone = createRoot(nodePhone)

    document.addEventListener("DOMContentLoaded", function () {

        function onRegister() {

            const form = nodePhone.closest('form[name="auth"]')

            const userEmail = form.querySelector('input[name="EMAIL"]');
            if (!userEmail.reportValidity()) {
                return 'Некорректно заполнен e-mail'
            }

            const userPersonalBirthday = form.querySelector('input[name="PERSONAL_BIRTHDAY"]');
            if (!userPersonalBirthday.reportValidity()) {
                return 'Некорректно заполнена дата рождения'
            }

            const currentPersonalBirthday = userPersonalBirthday.value;
            if (currentPersonalBirthday.length === 10) {

                const [day, month, year] = currentPersonalBirthday.split('/').map(Number);

                const personalBirthDay = new Date(year, month - 1, day);

                const today = new Date();
                const date18YearsAgo = new Date();
                date18YearsAgo.setFullYear(today.getFullYear() - 18);
                if (personalBirthDay > date18YearsAgo) {
                    return "Регистрация на данном сайте разрешена лицам старше 18 лет"
                }
            } else {
                return "Не верный формат даты рождения"
            }

            const userPassword = form.querySelector('input[name="PASSWORD"]');
            if (!userPassword.reportValidity()) {
                return 'Некорректно заполнен пароль'
            }

            const userConfirmPassword = form.querySelector('input[name="CONFIRM_PASSWORD"]');
            if (userConfirmPassword.value !== userPassword.value ) {
                return 'Пароль и подтверждения пароля не совпадают'
            }

            const userRules = form.querySelector('input[name="USER_RULES"]');
            if (!userRules || !userRules.checked) {
                return 'Необходимо принять пользовательское соглашение'
            }

            const userPolitics = form.querySelector('input[name="USER_POLITICS"]');
            if (!userPolitics || !userPolitics.checked) {
                return 'Необходимо принять политику конфиденциальности'
            }

            return undefined
        }

        phone.render(
            <StrictMode>
                <div className="mt-3">
                    <AuthorizationForm onValidate={onRegister}  isRegister={true}/>
                </div>
            </StrictMode>
        )
    });
}

const node = document.getElementById('js_auth_block');
if (node) {
    const auth = createRoot(node)
    const authBtn = document.getElementById('auth-button')
    if (authBtn) {
        authBtn.addEventListener("click", function () {

            document.querySelector('.overlay-box').classList.toggle('hidden');

            auth.render(
                <StrictMode>
                    <AuthorizationDialog hash={+new Date()}/>W
                </StrictMode>
            )
        });
    }
}

/**
 * PERSONAL CONTRAGENTS
 * @type {HTMLElement}
 */
const nodePopup = document.getElementById('js-initial-popup');
if (nodePopup) {
    const popup = createRoot(nodePopup)
    popup.render(
        <StrictMode>
            <FooterPopup />
        </StrictMode>
    )
}

const contragentForm = document.getElementById('createContragent');

if (contragentForm) {
    createRoot(contragentForm).render(<StrictMode><ContragentList/></StrictMode>);
}

/**
 * CATALOG POPUP
 * @type {Root}
 */
const root = createRoot(document.getElementById('boxInitialPopup'));
document.addEventListener('click', function (e) {

    if (e.target.closest('.image_cart')) {
        if (e.target.closest('.initialPopup') && e.target.tagName !== 'a' && e.target.tagName !== 'IMG') {
            let item = e.target.closest('.image_cart').querySelector('.initialPopup');
            if (item) {
                const productId = item.getAttribute('data-product-id');
                const areaBuy = item.getAttribute('data-area-buy');
                const areaBuyQuantity = item.getAttribute('data-area-quantity');
                const groupedProduct = item.getAttribute('data-grouped-product');

                const setVisible = () => {
                    root.render(
                        <StrictMode>
                            <CatalogProductPopup productId={productId} areaBuyQuantity={areaBuyQuantity}
                                                 areaBuy={areaBuy}
                                                 seePopup={false} groupedProduct={groupedProduct}
                                                 setVisible={setVisible}/>
                        </StrictMode>);
                }
                root.render(
                    <StrictMode>
                        <Suspense>
                            <CatalogProductPopup productId={productId} areaBuyQuantity={areaBuyQuantity} areaBuy={areaBuy}
                                             seePopup={true} groupedProduct={groupedProduct} setVisible={setVisible}/>
                        </Suspense>
                    </StrictMode>);
            }
        }
    }
});


/**
 * CATALOG POPUP
 * @type {Root}
 */
const claims = document.querySelectorAll('.buttonOpenClaims');
// TODO - возможно заменить инициализацию
if (claims.length > 0) {
    claims.forEach((item) => {
        createRoot(item).render(
            <StrictMode>
                <PersonalOrderClaims order_id={item.getAttribute('data-order-id')}
                                     orderNumber={item.getAttribute('data-order-number')}/>
            </StrictMode>);
    })
}