import React from 'react';

function ClaimButton({setPopupVisible, getDataForCreateClaim, order_id}) {
    const handleClick = () => {
        setPopupVisible(true);
        getDataForCreateClaim(order_id)
    }

    return (
        <div onClick={handleClick}
             className="cursor-pointer flex items-center justify-center w-full px-3 py-3 bg-yellowSt rounded-lg">
            <div className="mr-2">
                <svg width="20" height="20" viewBox="0 0 32 32" fill="none"
                     className="h-full"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_4309_287)">
                        <path
                            d="M29.2874 9.52918L22.8944 3.2985L25.2411 1.01132C25.6827 0.580923 26.3986 0.580923 26.8386 1.01132L31.6326 5.68356C32.0742 6.11396 32.0742 6.81161 31.6326 7.24045L29.2874 9.52918ZM28.1603 10.6277L21.7672 4.39702L2.63911 23.0409L9.03215 29.2716L28.1603 10.6277ZM1.08469 31.6986L7.9034 30.3701L1.51037 24.1394L0.147261 30.785C0.0356619 31.3288 0.528293 31.8074 1.08469 31.6986Z"
                            className="fill-dark"/>
                    </g>
                </svg>
            </div>
            <span className="text-dark md:text-sm text-xs font-semibold text-center">
                    Создать претензию
            </span>
        </div>
    )
}

export default ClaimButton;