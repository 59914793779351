import React from 'react';
import PropTypes from "prop-types";

Step1.propTypes = {
    step: PropTypes.number,
    setStep: PropTypes.func,
    options: PropTypes.object,
}
function Step1({setStep, step, options}) {
    const onCloseClick = () => {
        setStep(2)

        localStorage.setItem('age_access', '1')
    }

    if (step !== 1) {
        return null;
    }
    return (
            <div className="age-access-inner">
                <div className="age-access__text">
                    <h1 className="text-dark font-medium dark:font-light text-xl dark:text-textDarkLightGray">1
                        /
                        3</h1>
                    <h1 className="text-light-red font-medium dark:font-light text-xl dark:text-textDarkLightGray mb-4 text-center">
                        Уведомляем вас!</h1>
                    <div dangerouslySetInnerHTML={{ __html:  options.ATTENT_TEXT}}
                         className="age-access__text-part1 mb-4 text-xs dark:font-thin font-extralight text-textLight dark:text-borderColor">
                    </div>
                    <div dangerouslySetInnerHTML={{ __html:  options.ATTENT_TEXT2}}
                        className="age-access__text-part2 mb-5 text-xs dark:font-thin font-extralight text-textLight dark:text-borderColor">
                    </div>
                </div>
                <div className="age-access__buttons flex flex-row justify-between">
                    <a href="#" className="link_red_button border-greenButton border-2 px-3.5 py-2 rounded-md block w-3/5
                mr-3 md:text-sm text-xs font-medium text-center text-greenButton dark:border-none dark:bg-grayButton
                dark:font-light shadow-lg dark:text-white"
                       data-option="1" data-auth="false" onClick={onCloseClick}>Да, мне больше 18 лет</a>
                    <a href={options.ATTENT_NOT} className="age-access__button link_red_button
                            border-light-red border-2 px-9 py-2 rounded-lg block w-fit text-light-red font-medium
                            dark:border-none dark:bg-dark dark:font-light dark:text-white shadow-lg md:text-sm text-xs"
                       data-option="2" rel="nofollow">Нет</a>
                </div>
            </div>
    );
}

export default Step1;